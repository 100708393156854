<template>
  <div class="error-page">
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4 ml-auto mr-auto">
            <transition name="fade" mode="out-in">
              <b-card title="404" sub-title="Not found">
                <p>The resource requested could not be found on the system.</p>
              </b-card>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-page > .content {
  padding-top: 20vh;
}
</style>

<script>
export default {};
</script>
